import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useEffect, useState, useRef } from "react";
import { PortableText } from "@portabletext/react";
import "../styles/static.css";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import GlobalStyles from "../styles/global";
import { mobile, mobileUp, tablet, desktop } from "../styles/global";
import useSound from "use-sound";
import Footnotes from "../components/footnotes";
import Seo from "../components/seo";

const LiminalPage = () => {
  const limData = useStaticQuery(graphql`
    query LiminalQuery {
      sanityLiminalMag {
        title
        _rawDescription
        _rawCredits
        _rawImageInfo
        images {
          _key
          asset {
            gatsbyImageData(
              fit: SCALE
              width: 100
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
          }
          alt
          gridStart
          gridEnd
        }
        title1
        _rawBody1
        audio1 {
          asset {
            url
            extension
          }
          pronouns
        }
        title2
        _rawBody2
        audio2 {
          asset {
            url
            extension
          }
          pronouns
        }
        title3
        _rawBody3
        audio3 {
          asset {
            url
            extension
          }
          pronouns
        }
        title4
        _rawBody4
        audio4 {
          asset {
            url
            extension
          }
          pronouns
        }
      }
    }
  `);

  const backSlantStyle = {
    fontFamily: `"OS Safe Grotesk BS", sans-serif`,
    fontStyle: "normal",
  };

  const preStyle = {
    whiteSpace: "pre-wrap",
    fontFamily: "unset",
  };

  const components = {
    marks: {
      subtitle: ({ children }) => {
        return <span role="doc-subtitle">{children}</span>;
      },
      back: ({ children }) => {
        return <em style={backSlantStyle}>{children}</em>;
      },
      pre: ({ children }) => {
        return <pre style={preStyle}>{children}</pre>;
      },
      link: ({ children, value }) => {
        return (
          <a href={value.href} target="_blank" rel="noreferrer noopener">
            {children}
          </a>
        );
      },
      footnote: ({ children, value }) => {
        return (
          <span>
            {children}
            <sup>{value.refnum}</sup>
          </span>
        );
      },
    },
  };

  const headerRef = useRef();
  // const root = document.documentElement;
  const [overlayIsOpen, setOverlayIsOpen] = useState(false);
  const toggleOverlay = () => {
    setOverlayIsOpen(!overlayIsOpen);
  };

  const [headerHeight, setHeaderHeight] = useState();
  const colStyle = {
    // maxHeight: `calc(100vh - ${headerHeight})`,
    paddingTop: headerHeight,
  };

  const [overlayMaxHeight, setOverlayMaxHeight] = useState();
  useEffect(() => {
    // const mobile = window.matchMedia(`(max-width: 800px)`);
    // setMobileState(mobile.matches);
    setHeaderHeight(headerRef.current.getBoundingClientRect().height + "px");
    setOverlayMaxHeight(window.innerHeight - 16 - 55 + "px");

    function updateMediaSize() {
      // setMobileState(mobile.matches);
      setHeaderHeight(headerRef.current.getBoundingClientRect().height + "px");
      setOverlayMaxHeight(window.innerHeight - 16 - 55 + "px");
    }

    if (window.addEventListener) {
      window.addEventListener("resize", updateMediaSize);
      return function cleanup() {
        window.removeEventListener("resize", updateMediaSize);
      };
    } else {
      // backwards compatibility
      window.addEventListener(updateMediaSize);
      return function cleanup() {
        window.removeEventListener(updateMediaSize);
      };
    }
  }, []);
  const overlayStyle = {
    maxHeight: overlayMaxHeight,
  };

  const track10 = useRef();
  const track11 = useRef();
  const track20 = useRef();
  const track21 = useRef();
  const track30 = useRef();
  const track31 = useRef();
  const track40 = useRef();
  const track41 = useRef();

  const initialPlaying = {
    isPlaying: false,
    trackid: null,
  };
  const [playing, setPlaying] = useState(initialPlaying);

  const playTrack = (e) => {
    if (playing.isPlaying) {
      playing.trackid.current.pause();
    }
    console.log(e.target);
    console.log(e.target.dataset.trackid.current);
    setPlaying({
      isPlaying: true,
      trackid: e.target.dataset.trackid,
    });
    // console.log(playing);
  };

  const pauseTrack = (e) => {
    setPlaying(initialPlaying);
  };

  return (
    <LiminalWrapper className="liminal-wrapper">
      <GlobalStyles />
      <Seo title={limData.sanityLiminalMag.title} />
      <Header ref={headerRef}>
        <HeaderInfo className="xs">
          <h1>
            <Link to="/liminal">{limData.sanityLiminalMag.title}</Link>
          </h1>
          <PortableText
            value={limData.sanityLiminalMag._rawDescription}
            components={components}
          />
        </HeaderInfo>
        <HeaderCredits className="xs">
          <PortableText
            value={limData.sanityLiminalMag._rawCredits}
            components={components}
          />
        </HeaderCredits>
        <ImageToggleWrapper desktop>
          <ImageToggle onClick={toggleOverlay}>Image / Description</ImageToggle>
        </ImageToggleWrapper>
      </Header>
      <MobileNav aria-hidden="true" mobile>
        <ul>
          <li>
            <Link to={`#${limData.sanityLiminalMag.title1}`}>
              {limData.sanityLiminalMag.title1}
            </Link>
          </li>
          <li>
            <Link to={`#${limData.sanityLiminalMag.title2}`}>
              {limData.sanityLiminalMag.title2}
            </Link>
          </li>
          <li>
            <Link to={`#${limData.sanityLiminalMag.title3}`}>
              {limData.sanityLiminalMag.title3}
            </Link>
          </li>
          <li>
            <Link to={`#${limData.sanityLiminalMag.title4}`}>
              {limData.sanityLiminalMag.title4}
            </Link>
          </li>
        </ul>
      </MobileNav>
      <MobileOverlayWrapper style={overlayStyle}>
        <MobileOverlayInner>
          <MobileOverlayHeader mobile onClick={toggleOverlay}>
            <MobileOverlayToggle>Image / Description</MobileOverlayToggle>
          </MobileOverlayHeader>
          <ImagesOverlay open={overlayIsOpen}>
            <ImagesInfo>
              <div className="xs">
                <PortableText
                  value={limData.sanityLiminalMag._rawImageInfo}
                  components={components}
                />
              </div>
              <ImageToggle onClick={toggleOverlay}>Close</ImageToggle>
            </ImagesInfo>
            <ImagesBody className="images-body">
              {limData.sanityLiminalMag.images.map((image) => (
                <ImageSingle
                  key={image._key}
                  className="image-single"
                  gridStart={image.gridStart}
                  gridEnd={image.gridEnd}
                >
                  <GatsbyImage
                    image={image.asset.gatsbyImageData}
                    alt={image.alt}
                  />
                </ImageSingle>
              ))}
              <ImageSpacer></ImageSpacer>
            </ImagesBody>
          </ImagesOverlay>
        </MobileOverlayInner>
      </MobileOverlayWrapper>

      <ColsWrapper>
        <ScrollColumn style={colStyle}>
          <AudioPara aria-hidden="true" className="m-b xs">
            Play audio:{" "}
            <AudioWrapper key={`col1-track-0`}>
              <AudioTrack
                ref={track10}
                src={limData.sanityLiminalMag.audio1[0].asset.url}
              ></AudioTrack>
              {playing.trackid !== track10 ? (
                <AudioButton
                  onClick={(e) => {
                    if (playing.isPlaying) {
                      playing.trackid.current.pause();
                    }

                    track10.current.play();
                    setPlaying({
                      isPlaying: true,
                      trackid: track10,
                    });
                  }}
                  data-trackid={track10}
                >
                  {limData.sanityLiminalMag.audio1[0].pronouns}
                </AudioButton>
              ) : (
                <AudioButton
                  onClick={(e) => {
                    track10.current.pause();

                    setPlaying(initialPlaying);
                  }}
                  data-trackid={track10}
                >
                  Stop
                </AudioButton>
              )}
            </AudioWrapper>
            {limData.sanityLiminalMag.audio1[1] && " / "}
            <AudioWrapper key={`col1-track-1`}>
              <AudioTrack
                ref={track11}
                src={limData.sanityLiminalMag.audio1[1].asset.url}
              ></AudioTrack>
              {playing.trackid !== track11 ? (
                <AudioButton
                  onClick={(e) => {
                    if (playing.isPlaying) {
                      playing.trackid.current.pause();
                    }

                    track11.current.play();
                    setPlaying({
                      isPlaying: true,
                      trackid: track11,
                    });
                  }}
                  data-trackid={track11}
                >
                  {limData.sanityLiminalMag.audio1[1].pronouns}
                </AudioButton>
              ) : (
                <AudioButton
                  onClick={(e) => {
                    track11.current.pause();

                    setPlaying(initialPlaying);
                  }}
                  data-trackid={track11}
                >
                  Stop
                </AudioButton>
              )}
            </AudioWrapper>
          </AudioPara>
          <ColTitle id={limData.sanityLiminalMag.title1} className="p-b">
            {limData.sanityLiminalMag.title1}
          </ColTitle>
          <PortableText
            value={limData.sanityLiminalMag._rawBody1}
            components={components}
          />
        </ScrollColumn>
        <ScrollColumn style={colStyle}>
          <AudioPara aria-hidden="true" className="m-b xs">
            Play audio:{" "}
            <AudioWrapper key={`col2-track-0`}>
              <AudioTrack
                ref={track20}
                src={limData.sanityLiminalMag.audio2[0].asset.url}
              ></AudioTrack>
              {playing.trackid !== track20 ? (
                <AudioButton
                  onClick={(e) => {
                    if (playing.isPlaying) {
                      playing.trackid.current.pause();
                    }

                    track20.current.play();
                    setPlaying({
                      isPlaying: true,
                      trackid: track20,
                    });
                  }}
                  data-trackid={track20}
                >
                  {limData.sanityLiminalMag.audio2[0].pronouns}
                </AudioButton>
              ) : (
                <AudioButton
                  onClick={(e) => {
                    track20.current.pause();

                    setPlaying(initialPlaying);
                  }}
                  data-trackid={track20}
                >
                  Stop
                </AudioButton>
              )}
            </AudioWrapper>
            {limData.sanityLiminalMag.audio2[1] && " / "}
            <AudioWrapper key={`col2-track-1`}>
              <AudioTrack
                ref={track21}
                src={limData.sanityLiminalMag.audio2[1].asset.url}
              ></AudioTrack>
              {playing.trackid !== track21 ? (
                <AudioButton
                  onClick={(e) => {
                    if (playing.isPlaying) {
                      playing.trackid.current.pause();
                    }

                    track21.current.play();
                    setPlaying({
                      isPlaying: true,
                      trackid: track21,
                    });
                  }}
                  data-trackid={track21}
                >
                  {limData.sanityLiminalMag.audio2[1].pronouns}
                </AudioButton>
              ) : (
                <AudioButton
                  onClick={(e) => {
                    track21.current.pause();

                    setPlaying(initialPlaying);
                  }}
                  data-trackid={track21}
                >
                  Stop
                </AudioButton>
              )}
            </AudioWrapper>
          </AudioPara>
          <ColTitle id={limData.sanityLiminalMag.title2} className="p-b">
            {limData.sanityLiminalMag.title2}
          </ColTitle>
          <PortableText
            value={limData.sanityLiminalMag._rawBody2}
            components={components}
          />
        </ScrollColumn>
        <ScrollColumn style={colStyle}>
          <AudioPara aria-hidden="true" className="m-b xs">
            Play audio:{" "}
            <AudioWrapper key={`col3-track-0`}>
              <AudioTrack
                ref={track30}
                src={limData.sanityLiminalMag.audio3[0].asset.url}
              ></AudioTrack>
              {playing.trackid !== track30 ? (
                <AudioButton
                  onClick={(e) => {
                    if (playing.isPlaying) {
                      playing.trackid.current.pause();
                    }

                    track30.current.play();
                    setPlaying({
                      isPlaying: true,
                      trackid: track30,
                    });
                  }}
                  data-trackid={track30}
                >
                  {limData.sanityLiminalMag.audio3[0].pronouns}
                </AudioButton>
              ) : (
                <AudioButton
                  onClick={(e) => {
                    track30.current.pause();

                    setPlaying(initialPlaying);
                  }}
                  data-trackid={track30}
                >
                  Stop
                </AudioButton>
              )}
            </AudioWrapper>
            {limData.sanityLiminalMag.audio3[1] && " / "}
            <AudioWrapper key={`col3-track-1`}>
              <AudioTrack
                ref={track31}
                src={limData.sanityLiminalMag.audio3[1].asset.url}
              ></AudioTrack>
              {playing.trackid !== track31 ? (
                <AudioButton
                  onClick={(e) => {
                    if (playing.isPlaying) {
                      playing.trackid.current.pause();
                    }

                    track31.current.play();
                    setPlaying({
                      isPlaying: true,
                      trackid: track31,
                    });
                  }}
                  data-trackid={track31}
                >
                  {limData.sanityLiminalMag.audio3[1].pronouns}
                </AudioButton>
              ) : (
                <AudioButton
                  onClick={(e) => {
                    track31.current.pause();

                    setPlaying(initialPlaying);
                  }}
                  data-trackid={track31}
                >
                  Stop
                </AudioButton>
              )}
            </AudioWrapper>
          </AudioPara>
          <ColTitle id={limData.sanityLiminalMag.title3} className="p-b">
            {limData.sanityLiminalMag.title3}
          </ColTitle>
          <PortableText
            value={limData.sanityLiminalMag._rawBody3}
            components={components}
          />
          <Footnotes blocks={limData.sanityLiminalMag._rawBody3} />
        </ScrollColumn>
        <ScrollColumn style={colStyle}>
          <AudioPara aria-hidden="true" className="m-b xs">
            Play audio:{" "}
            <AudioWrapper key={`col4-track-0`}>
              <AudioTrack
                ref={track40}
                src={limData.sanityLiminalMag.audio4[0].asset.url}
              ></AudioTrack>
              {playing.trackid !== track40 ? (
                <AudioButton
                  onClick={(e) => {
                    if (playing.isPlaying) {
                      playing.trackid.current.pause();
                    }

                    track40.current.play();
                    setPlaying({
                      isPlaying: true,
                      trackid: track40,
                    });
                  }}
                  data-trackid={track40}
                >
                  {limData.sanityLiminalMag.audio4[0].pronouns}
                </AudioButton>
              ) : (
                <AudioButton
                  onClick={(e) => {
                    track40.current.pause();

                    setPlaying(initialPlaying);
                  }}
                  data-trackid={track40}
                >
                  Stop
                </AudioButton>
              )}
            </AudioWrapper>
            {limData.sanityLiminalMag.audio4[1] && " / "}
            <AudioWrapper key={`col4-track-1`}>
              <AudioTrack
                ref={track41}
                src={limData.sanityLiminalMag.audio4[1].asset.url}
              ></AudioTrack>
              {playing.trackid !== track41 ? (
                <AudioButton
                  onClick={(e) => {
                    if (playing.isPlaying) {
                      playing.trackid.current.pause();
                    }

                    track41.current.play();
                    setPlaying({
                      isPlaying: true,
                      trackid: track41,
                    });
                  }}
                  data-trackid={track41}
                >
                  {limData.sanityLiminalMag.audio4[1].pronouns}
                </AudioButton>
              ) : (
                <AudioButton
                  onClick={(e) => {
                    track41.current.pause();

                    setPlaying(initialPlaying);
                  }}
                  data-trackid={track41}
                >
                  Stop
                </AudioButton>
              )}
            </AudioWrapper>
          </AudioPara>
          <ColTitle id={limData.sanityLiminalMag.title4} className="p-b">
            {limData.sanityLiminalMag.title4}
          </ColTitle>
          <PortableText
            value={limData.sanityLiminalMag._rawBody4}
            components={components}
          />
        </ScrollColumn>
      </ColsWrapper>
    </LiminalWrapper>
  );
};

const LiminalWrapper = styled.main`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Header = styled.section`
  padding: var(--padding);
  padding-bottom: calc(var(--padding-2x) * 1);
  // margin-bottom: calc(var(--padding-2x) * -1);
  // position: sticky;
  position: fixed;
  z-index: 2;
  inset: 0 0 auto 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  // display: flex;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: var(--padding-2x);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 40%,
    rgba(0, 0, 0, 0) 100%
  );

  ${mobile} {
    padding-bottom: var(--padding-2x);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 60%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  & > div > *:not(:last-child) {
    margin-bottom: calc(var(--text-xs) * var(--text-xs-line));
  }
`;

const HeaderInfo = styled.div`
  // flex: 2;
  grid-column: span 2;

  & h1 a {
    border-bottom: none !important;
  }
`;

const HeaderCredits = styled.div`
  ${mobile} {
    grid-column: span 2;
  }
`;

const ImageToggleWrapper = styled.div`
  // flex: 1;
  align-self: start;
  // justify-content: start;

  ${mobile} {
    display: ${(props) => (props.desktop ? "none" : "block")};
  }
`;

const ImageToggle = styled.button`
  width: fit-content;
`;

const ColsWrapper = styled.section`
  flex: 1;
  display: flex;
  // grid-template-columns: repeat(4, 1fr);
  // grid-gap: var(--padding);
  gap: var(--padding-2x);
  padding-inline: var(--padding);
  width: 100%;

  ${mobile} {
    padding-top: var(--body-padding-top);
    padding-bottom: 132px;
    flex-direction: column;
    gap: var(--padding-xl);
  }
`;

const ScrollColumn = styled.div`
  ${desktop} {
    height: 100vh;
    // max-height: calc(100vh - var(--header-height));
    // max-height: calc(100vh - 93px);
    // padding-top: 120px;
    // padding-top: var(--header-height);
    padding-bottom: 16px;
    overflow-y: scroll;
    flex: 1;
    transition: flex var(--transition);

    & > p {
      // transition: font-size var(--transition), line-height var(--transition);
    }

    &:hover {
      flex: 3;

      & * {
        font-size: var(--text-body-hover) !important;
        line-height: var(--text-body-line) !important;
      }

      & .xs,
      & .xs * {
        font-size: var(--text-body) !important;
        line-height: var(--text-body-line) !important;
      }

      & sup {
        font-size: 60% !important;
        vertical-align: super !important;
      }

      & ol {
        padding-left: 28px !important;
      }
    }
  }

  ${mobile} {
    padding-top: 0 !important;
  }
`;

const ColTitle = styled.h2`
  text-transform: uppercase;
  // position: sticky;
  // top: 0;
`;

const MobileOverlayWrapper = styled.section`
  ${mobile} {
    position: fixed;
    bottom: 52px;
    left: var(--padding);
    right: var(--padding);
    background-color: var(--bg);
    padding: var(--padding);
    box-shadow: rgb(255 255 255 / 35%) 0px 0px 8px;
    overflow-y: scroll;
    z-index: 3;
  }
`;

const MobileOverlayInner = styled.div``;

const MobileOverlayHeader = styled.div`
  width: 100%;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 5;

  ${desktop} {
    display: none;
  }
`;

const MobileOverlayToggle = styled.button`
  width: fit-content;
`;

const ImagesOverlay = styled.div`
  ${desktop} {
    position: fixed;
    z-index: 3;
    inset: 0 auto 0 0;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 50vw;
    overflow-y: scroll;
    background-color: var(--bg);
    border-right: 1px solid var(--fg);
    padding-inline: var(--padding);
    padding-bottom: var(--padding-xl);
    // display: flex;
    // flex-direction: column;
    // gap: var(--padding-2x);
    transition: transform var(--transition);
    transform: ${(props) =>
      props.open ? "translateX(0)" : "translateX(-100%)"};
  }

  ${mobile} {
    height: ${(props) => (props.open ? "auto" : "0px")};
      padding-bottom: ${(props) => (props.open ? "var(--padding)" : "0px")};

  };
    overflow-y: ${(props) => (props.open ? "scroll" : "hidden")};
  };
  }
`;

const ImagesInfo = styled.div`
  & > div {
    margin-bottom: var(--padding);
  }
  position: sticky;
  top: 0;
  z-index: 2;
  padding-top: var(--padding);
  padding-bottom: var(--padding-xl);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 40%,
    rgba(0, 0, 0, 0) 100%
  );
`;

const ImagesBody = styled.div`
  ${desktop} {
    padding-bottom: var(--padding-xl);
  }

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 100%;
  row-gap: var(--padding-xl);
`;

const ImageSingle = styled.div`
  grid-column-start: ${(props) => props.gridStart};
  grid-column-end: ${(props) => props.gridEnd};
`;

const ImageSpacer = styled.div`
  grid-column: 1 / span all;
  height: var(--padding);

  ${mobile} {
    display: none;
  }
`;

const AudioPara = styled.p`
  text-transform: uppercase;
`;

const AudioWrapper = styled.span``;

const AudioTrack = styled.audio``;

const AudioButton = styled.button``;

const MobileNav = styled.nav`
  ${desktop} {
    display: ${(props) => (props.mobile ? "none" : "block")};
  }

  position: fixed;
  inset: auto 0 0 0;
  left: 0;
  // right: 0;
  width: 100vw;
  bottom: 0;
  padding: var(--padding);
  overflow-x: scroll;
  background-color: var(--bg);
  // background: linear-gradient(
  //   180deg,
  //   rgba(0, 0, 0, 0) 0%,
  //   rgba(0, 0, 0, 1) 60%
  // );

  & > ul {
    display: flex;
    // column-gap: var(--padding);
    list-style-type: none;

    & > li {
      flex-shrink: 0;
      padding-right: var(--padding);
    }
  }
`;

export default LiminalPage;
