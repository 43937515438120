import React from "react";
import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";

export const breakpoints = {
  mobile: "@media (max-width: 768px)",
  // mobileUp: "@media (min-width: 768px)",
  tablet: "@media (max-width: 1024px)",
  // desktop: "@media (max-width: 1440px)",
  desktop: "@media (min-width: 768px)",
  desktopUp: "@media (min-width: 1025px)",
};

export const mobile = breakpoints.mobile;
// export const mobileUp = breakpoints.mobileUp;
export const tablet = breakpoints.tablet;
export const desktop = breakpoints.desktop;
export const desktopUp = breakpoints.desktopUp;

const GlobalStyles = () => (
  <>
    <Global styles={reset} />
    <Global styles={styles} />
  </>
);

const styles = css`
  :root {
    --padding: 16px;
    --padding-2x: calc(var(--padding) * 2);
    --padding-xl: 72px;

    --font-primary: "OS Safe Grotesk", system-ui;
    --bg: #000000;
    --fg: #ffffff;
    --hyperlink: underline;

    --text-xs: 12px;
    --text-xs-line: 1.25;
    --text-xs-hover: 24px;

    --text-body: 18px;
    --text-body-line: 1.25;
    --text-body-hover: 30px;

    --transition: 300ms ease-in-out;
    --body-padding-top: 138px;
    --scroll-padding-top: calc(var(--body-padding-top) + var(--padding-2x));

    --overlay-bottom: calc(var(--padding) + var(--padding) + 22.5px);
  }

  html {
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
    scroll-behavior: smooth;
    scroll-padding-top: var(--scroll-padding-top) !important;
  }

  * {
    font-size: var(--text-body) !important;
  }

  sup {
    font-size: 60% !important;
    vertical-align: 22%;
  }

  body {
    font-size: inherit;
    line-height: var(--text-body-line);
    font-family: var(--font-primary);
    color: var(--fg);
  }

  pre {
    line-height: var(--text-body-line) !important;
  }

  *::-webkit-scrollbar {
    display: none;
  }

  body {
    background-color: var(--bg);
  }

  p,
  .p {
    font-size: var(--text-body);
    line-height: var(--text-body-line);
  }

  p.xs,
  .xs,
  .xs * {
    font-size: var(--text-xs) !important;
    line-height: var(--text-xs-line);
  }

  a,
  button {
    border-bottom: 1px solid var(--fg) !important;
    cursor: pointer !important;
    transition: box-shadow var(--transition);

    &:focus-visible {
      box-shadow: 0px 0px 8px 0px var(--fg), inset 0px 0px 4px 0px var(--fg) !important;
      border-radius: 2px;
      outline: none !important;
    }
  }

  button {
    text-transform: uppercase;
  }

  .m-b {
    margin-bottom: calc(var(--text-body) * var(--text-body-line));
  }

  .p-b {
    padding-bottom: calc(var(--text-body) * var(--text-body-line));
  }
`;

const reset = css`
  body {
    margin: 0px;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    // transition: font-size var(--transition), line-height var(--transition);
  }

  ul,
  ol,
  blockquote {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    /*  This prevents chrome from annoyingly adjusting the scroll to try and compensate for moving elements */
    overflow-anchor: none;
    box-sizing: border-box;
  }

  button {
    -webkit-appearance: none;
    border: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: inherit;
    -webkit-box-shadow: 0 0 0px 0px transparent inset;
    transition: background-color 5000000s ease-in-out 0s;
  }

  /* Prevent safari blue links */
  button,
  option {
    color: inherit;
  }
`;

export default GlobalStyles;
