import React from "react";
import { PortableText } from "@portabletext/react";
import styled from "@emotion/styled";

function Footnotes({ blocks }) {
  const notes = blocks
    .filter(({ _markDefs }) => _markDefs !== null)
    .reduce((acc, curr) => {
      return [...acc, ...curr.markDefs];
    }, [])
    .filter(({ _type }) => _type === "footnote");

  const backSlantStyle = {
    fontFamily: `"OS Safe Grotesk BS", sans-serif`,
    fontStyle: "normal",
  };

  const components = {
    marks: {
      back: ({ children }) => {
        return <em style={backSlantStyle}>{children}</em>;
      },
    },
  };

  return (
    <FootnotesList className="xs">
      {notes.map((note) => (
        <li key={note._key}>
          <PortableText
            value={note.refblocks}
            components={components}
          ></PortableText>
        </li>
      ))}
    </FootnotesList>
  );
}

const FootnotesList = styled.ol`
  padding-top: 240px;
  list-style-type: numbered;
  padding-left: 17px;
  padding-right: 10%;

  & li::marker {
    font-variant-numeric: normal !important;
  }
`;

export default Footnotes;
